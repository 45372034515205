<template>
  <div class="digitization">
    <h1>Fine Art Reproduction</h1>
    <h4>Have you created artwork that you would like to sell reproductions of?</h4>
    <p>This is the ultimate way to preserve and reproduce pieces of artwork for centuries to come. It is perfect for an artist to make limited edition runs or just one reproduction of their original piece. Depending on the artwork it is either scanned on a flatbed scanner or custom lit and photographed at our discretion. We prefer only to work directly with artists themselves. If you are not the artist we will need written permission from the copyright owner of the artwork to make a reproduction.</p>
      <br/>

  <div>



    <div class="inline-container" @click="showPopup(1)" style="cursor: pointer;">
      <h4>Digital Capture</h4>
    </div>

    <transition name="fade">
      <div v-if="showPopup1" class="popup-overlay" @click="closePopup">
        <div class="popup">

          <span class="close-icon" @click="closePopup"><img src="../assets/x.svg" /></span>
          <h4>Digital Capture</h4>
          <h5>$0.04 per square inch
          <br/>
          $20 minimum (no minimum if you buy color proofing)</h5>
          <p>Start by getting your artwork captured using our flatbed scanner or digital camera. Our team will decide which format is best suited to your artwork.</p>
          <br>
          <p>Delivery of a web-ready .jpg is included in this service, for easily updating your art on your website, or submitting it elsewhere online.</p>

        </div>
      </div>
    </transition>

      <div style="margin-top: -5px;">
        <div class="input-container">
          <input placeholder="width" type="number" v-model="input1" id="input1" @input="calculateResult">
        </div>
        <div class="input-container">
        <h4 style="font-size: 0.8em; font-weight: 700; opacity: 0.67;">x</h4>
        </div>
        <div class="input-container">
          <input placeholder="height" type="number" v-model="input2" id="input2" @input="calculateResult">
        </div>
      </div>

<br>

    <div style="margin-top: -15px;">
      <div @click="showPopup(2)" style="cursor: pointer;">
        <h4>Color Proofing</h4>
      </div>
      <div>
        <input type="checkbox" v-model="checkboxValues[0]" @change="calculateResult">
      </div>
    </div>

    <transition name="fade">
      <div v-if="showPopup2" class="popup-overlay" @click="closePopup">
        <div class="popup">

          <span class="close-icon" @click="closePopup"><img src="../assets/x.svg" /></span>
          <h4>Color Proofing</h4>
          <h5>$40 per piece</h5>
          <p>
          If you want the prints we make to look as close as possible to your original, then you will want to add color proofing.
          </p>
          <br/>
          <p>If you don’t add color proofing, your image will still be the same high resolution capture, and will meet our requirements for tonal values, but colors may or may not look the way they do on your original.<br/><br/>The differences may not be major. If your client has never seen your artwork in person there’s good chance they won’t notice, and even if they do, it might not affect their appreciation of your work.</p>
          <br>
          <p>Add this service, if color accuracy is a priority.</p>
        </div>
      </div>
    </transition>

<br>

    <div style="margin-top: -15px;">
      <div @click="showPopup(3)" style="cursor: pointer;">
        <h4>Background Removal</h4>
      </div>      
      <div>
        <input type="checkbox" v-model="checkboxValues[1]" @change="calculateResult">
      </div>
    </div>

    <transition name="fade">
      <div v-if="showPopup3" class="popup-overlay" @click="closePopup">
        <div class="popup">

          <span class="close-icon" @click="closePopup"><img src="../assets/x.svg" /></span>
          <h4>Background Removal</h4>
            <h5>
            $30 per piece
            </h5>
            <p>
            This option is for artwork where the subject is floating in negative space, and you want the background to be pure white. With any digital capture, detail is preserved, including the characteristics of the paper.<br><br>That is to say, the paper's color, which is never pure white, often very yellow, along with the paper's texture, such as heavy watercolor texture, will show.<br><br>If you would like your work to be presented on a clean white background, we are happy to do so, but requires some additional editing.
            </p>
        </div>
      </div>
    </transition>


<br>

    <div style="margin-top: -15px;">
      <div @click="showPopup(4)" style="cursor: pointer;">
        <h4>Metallic Pigments</h4>
      </div>    
      <div>
        <input type="checkbox" v-model="checkboxValues[2]" @change="calculateResult">
      </div>
    </div>

    <transition name="fade">
      <div v-if="showPopup4" class="popup-overlay" @click="closePopup">
        <div class="popup">

          <span class="close-icon" @click="closePopup"><img src="../assets/x.svg" /></span>
          <h4>Metallic Pigments</h4>
            <h5>
            $15 per piece
            </h5>
            <p>
            This service is for capturing artwork that includes metallic pigments.<br><br>Metallic pigments reflect light differently than matte pigmented color, which make them more difficult to capture, without them turning pure white when hit with flash-strobes or under the harsh light of a scanner.<br><br>There are work-arounds though, they just require a few extra steps in our workflow.
            </p>
        </div>
      </div>
    </transition>


<br>

    <div style="margin-top: -15px;">
      <div @click="showPopup(5)" style="cursor: pointer;">
        <h4>Full Resolution File</h4>
      </div>    
      <div>
        <input type="checkbox" v-model="checkboxValues[3]" @change="calculateResult">
      </div>
    </div>

    <transition name="fade">
      <div v-if="showPopup5" class="popup-overlay" @click="closePopup">
        <div class="popup">

          <span class="close-icon" @click="closePopup"><img src="../assets/x.svg" /></span>
          <h4>Full Resolution File</h4>
            <h5>$30 per piece
            <br/>
            (web-ready .jpg included with digital capture)
            </h5>          
            <p>
            A low resolution .jpg is included with your digital capture. If you would like to get the full resolution file intended for printing, add a full resolution file to your order.
            <br>
            <br>
            Otherwise we will keep your file on a secure drive at our shop for future print orders. The high resolution digital file will be available for purchase up to two years after your capture.
            </p>
        </div>
      </div>
    </transition>



  
  <div style="height: 50px; overflow: hidden; padding-top: 10px;">
    <transition name="fade">
      <h3 style="margin-top: 0px;" v-if="showResult">{{ formattedTotalAfterAdditions }}</h3>
    </transition>
  </div>


  <a target="_blank" style="
      background-color: transparent; 
      border: 1px solid #546476;
      color: #2c3e50; 
      height: 40px; 
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px; 
      line-height: 38px; 
      padding: 0 10px; 
      border-radius: 8px; 
      font-weight: 500; 
      font-size: 14px; 
      cursor: pointer; display: 
      inline-block;"
      href="https://squareup.com/appointments/buyer/widget/19hk25gf2q1cbx/ESPTFE637VPJ5">Schedule Artwork Drop-off
      </a>

<br>
<br>
      
    
  </div>



      
  </div>




</template>


<script>
export default {
  data() {
    return {
      input1: null,
      input2: null,
      checkboxValues: [false, false, false, false],
      showResult: false,
      showPopup1: false,
      showPopup2: false,
      showPopup3: false,
      showPopup4: false,
      showPopup5: false,
    };
  },
  computed: {
    isInputsValid() {
      return this.input1 !== null && this.input2 !== null;
    },
    withoutMinimum() {
      return this.isInputsValid ? this.input1 * this.input2 * 0.04 : 0;
    },
    withMinimum() {
      return Math.max(this.withoutMinimum, 20);
    },
    totalAfterAdditions() {
      const baseValue = this.checkboxValues.reduce((acc, isChecked, index) => {
        if (isChecked) {
          return acc + [40, 30, 15, 30][index];
        }
        return acc;
      }, this.checkboxValues[0] ? this.withoutMinimum : this.withMinimum);

      return baseValue;
    },
    formattedTotalAfterAdditions() {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(this.totalAfterAdditions);
    },
  },
  methods: {
    calculateResult() {
      this.showResult = this.isInputsValid;
    },
    showPopup(popupNumber) {
      // Close all popups before showing a new one
      this.closePopup();

      // Show the selected popup
      this[`showPopup${popupNumber}`] = true;
    },
    closePopup() {
      // Close all popups
      this.showPopup1 = false;
      this.showPopup2 = false;
      this.showPopup3 = false;
      this.showPopup4 = false;
      this.showPopup5 = false;
    },
  },
};
</script>

<style scoped>
h5 {
  margin-top: -5px;
} 

.input-container {
      display: inline-block;
      /* or use float: left; */
      margin-right: 0px; /* Adjust the margin as needed */
    }

.input-container input{
  width: 50px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

details {
  display: none;
}


summary{
  font-size: 14px;
  margin-left: -10px;
  padding-top: 10px;
  opacity: 0.67;
}

.inline-container {
      display: inline-block;
      padding: 0 30px;
      vertical-align: middle; /* Align vertically in the middle if needed */
    }

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

button {
  margin: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

/**
input[type="checkbox"] {
    accent-color: rgba(242, 157, 75, 1.0);
}

input{
  color: rgba(242, 157, 75, 1.0);
  font-size: 1em;
  font-weight: 700;
  background-color: rgba(242, 157, 75, 0.0); 
  border: 0px solid #546476;
  border-radius: 8px;
}

::placeholder{
  font-size: 14px;
  font-weight: 500;
}
*/




</style>




